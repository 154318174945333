import React from "react";
import HubspotForm from "react-hubspot-form";

const HubspotContactUs = () => {
  return (
    <>
      <div className="main-wrapper">
        <div className="faq-hero-section">
          <div className="side-padding">
            <div className="vertical-spacing home-hero-spacing">
              <div className="container-new">
                <div className="faq-hero-wrap">
                  <div>
                    <h6 className="heading-extralarge faq-heading">
                      Fill out the form, We'll Reach Out to You Soon!
                    </h6>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="text-center">
            <div className="w-100 mb-5">
              <div className="vertical-spacing">
                <div className="container-new">
                  <div className="side-padding">
                    <HubspotForm
                      portalId="5241389"
                      formId="555b8160-dc00-49d7-8f4d-3cf37fcf0809"
                      onSubmit={() => console.log("Submit!")}
                      onReady={(form) => console.log("Form ready!")}
                      loading={<div>Loading...</div>}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HubspotContactUs;
