import React from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
const Navbar = () => {
  return (
    <>
      {/* <nav className="navbar navbar-expand-lg navbar-light bg-light">
        <div className="container-fluid">
          <button
            class="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span class="navbar-toggler-icon"></span>
          </button>
          <div class="collapse navbar-collapse" id="navbarTogglerDemo01">
            <a class="navbar-brand" href="#">
              Hidden brand
            </a>
            <ul class="navbar-nav me-auto mb-2 mb-lg-0">
              <li class="nav-item">
                <a class="nav-link active" aria-current="page" href="#">
                  Home
                </a>
              </li>
              <li class="nav-item">
                <a class="nav-link" href="#">
                  Link
                </a>
              </li>
              <li class="nav-item">
                <a
                  class="nav-link disabled"
                  href="#"
                  tabindex="-1"
                  aria-disabled="true"
                >
                  Disabled
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav> */}
      <div className="main-navbar w-nav">
        <div className="side-padding">
          <button
            className="navbar-toggler navBtn"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo01"
            aria-controls="navbarTogglerDemo01"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="material-icons">menu</span>
          </button>
          <div className="container-new nav-container-2 d-flex align-items-center justify-content-space-between">
            <div
              className="wrapperNavbar"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
              }}
            >
              <a
                href="/"
                className="cursor-pointer brand w-nav-brand w--current"
              >
                <img
                  src="images/housing.png"
                  alt="Housing"
                  className="headerLogo"
                />
              </a>
              <div
                className="brandlogoHeader"
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  gap: "4px",
                  padding: "8px 0 0 0 ",
                }}
              >
                <span
                // style={{ fontFamily: 'Playball', fontSize: '22px', padding: '0 6px 0 0' }}
                >
                  Our Brands
                </span>
                <a
                  href="https://www.auctionsoftware.com/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/auction_sftw_logo.svg"
                    alt="AuctionPay.com Logo"
                    width="40"
                    height="40"
                  />
                </a>

                <a href="https://auction.io" target="_blank" rel="noreferrer">
                  <img
                    src="/images/auctionio_logo.svg"
                    alt="AuctionPay.com Logo"
                    width="40"
                    height="40"
                  />
                </a>
                <a
                  href="https://paymentgateway.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/payment_gateway_logo.svg"
                    alt="AuctionPay.com Logo"
                    width="40"
                    height="40"
                  />
                </a>
                <a
                  href="https://simpleauctionsite.com"
                  target="_blank"
                  rel="noreferrer"
                >
                  <img
                    src="/images/simple_auction_logo.svg"
                    alt="AuctionPay.com Logo"
                    width="40"
                    height="40"
                  />
                </a>
                <a href="https://doorsey.com" target="_blank" rel="noreferrer">
                  <img
                    src="/images/doorsdey_logo.svg"
                    alt="AuctionPay.com Logo"
                    width="40"
                    height="40"
                  />
                </a>
              </div>
            </div>
            <nav role="navigation" className="nav__menu-wrapper w-nav-menu">
              <div
                className="collapse navbar-collapse "
                id="navbarTogglerDemo01"
              >
                <div className="nav-link-wrapper">
                  <a
                    href="https://app.doorsey.com/"
                    target="_blank"
                    className="nav-link w-nav-link"
                  >
                    Buy Now
                  </a>
                  <Link
                    activeClassName="active"
                    to="/faqs"
                    className="nav-link w-nav-link"
                  >
                    FAQs
                  </Link>
                </div>
                <div className="nav-button-wrapper">
                  <Link
                    activeClassName="active"
                    to="/book-a-demo"
                    className="nav-link w-nav-link"
                  >
                    Book A Demo
                  </Link>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
