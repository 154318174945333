import React from "react";
import InputField from "../../Component/InputField/InputField";
import "./Home.css";
import ButtonComponent from "../../Component/Button";

const Home = (props) => {
  return (
    <>
      <div className="main-wrapper">
        {/* Section-one */}
        <div className="new-home-hero">
          <div className="side-padding">
            <div className="vertical-spacing home-hero-spacing">
              <div className="container-new">
                <div className="hero-flex">
                  <div className="hero-content-left">
                    <div className="margin-bottom-24">
                      <h1 className="heading-extralarge new-h1">
                        A Modern Commerce Platform for{" "}
                        <span className="typed-text">Real Estate</span>
                      </h1>
                    </div>
                    <div className="margin-bottom-24">
                      <p>
                        Today’s home buyers want a modern online experience.
                        They want information at their fingertips, and they want
                        to engage online by asking questions and starting the
                        offer process. Doorsey.com provides a world-class
                        commerce solution that’s easy to use and automates your
                        daily tasks, freeing up time to focus on growing your
                        business.
                      </p>
                    </div>
                  </div>
                  <div className="hero-content-right">
                    <div className="hero-image-wrapper">
                      <img src="images/first-image.png" alt="First" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-two */}

        {/* Section-three */}
        <div className="benefits-section wf-section">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Enable Commerce</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Your website is a crucial place to meet buyers right
                        where you want them to be—on your site. Doorsey.com’s
                        commerce solution simply integrates directly into your
                        website without the need for a developer, and helps you
                        engage buyers with high quality listings, offer
                        transparency, and flexible buying options.
                      </p>
                    </div>
                    <div className="testimonial-profile-flex">
                      <div className="testimonee-profile">
                        <img
                          src="images/person1.png"
                          alt="Person1"
                          className="image-100 cover"
                        />
                      </div>
                      <div className="testimonee-text-wrap">
                        <div className="margin-bottom-24">
                          <div className="testimonee-name">
                            The offer process can be an opaque, black box for
                            consumers. Doorsey.com is solving that problem by
                            adding transparency for consumers and clear,
                            predictable terms for agents.
                          </div>
                        </div>
                        <div className="fred">
                          Fred McGill - Broker | Simple Showing, Inc.
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/Photo_1.png"
                        alt="Photo-1"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-four */}
        <div className="benefits-section wf-section">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex reverse">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">
                        Engage and Manage Your Clients
                      </h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        From listing to offer management directly from your
                        website, our robust communications platform for your
                        teams and clients will keep everyone informed every step
                        of the way.
                      </p>
                    </div>
                    <div className="testimonial-profile-flex">
                      <div className="testimonee-profile">
                        <img
                          src="images/person2.png"
                          alt="Person1"
                          className="image-100 cover"
                        />
                      </div>
                      <div className="testimonee-text-wrap">
                        <div className="margin-bottom-24">
                          <div className="testimonee-name">
                            It was amazing. I was able to do other work and help
                            other clients instead of focusing on organizing all
                            of the offers and their unique contingencies.
                          </div>
                        </div>
                        <div className="fred">
                          Roselyn McDonald - Realtor | Guardian Realty Group
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/Photo_2.jpeg"
                        alt="Photo-1"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-five */}
        <div className="benefits-section wf-section">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Sell More Homes</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Doorsey.com’s easy-to-use platform delivers efficiency
                        and transparency, giving clients the confidence to buy
                        and sell directly from your website. Tech-enabled
                        services will allow you to rise above the competition
                        and get more listings.
                      </p>
                    </div>
                    <div className="testimonial-profile-flex">
                      <div className="testimonee-profile">
                        <img
                          src="images/person3.png"
                          alt="Person1"
                          className="image-100 cover"
                        />
                      </div>
                      <div className="testimonee-text-wrap">
                        <div className="margin-bottom-24">
                          <div className="testimonee-name">
                            Doorsey.com is the only platform out there that will
                            help me accomplish my goal of bringing a higher
                            level of transparency and simplicity to residential
                            real estate transactions for buyers and sellers.
                          </div>
                        </div>
                        <div className="fred">
                          Mathew Speer - Broker | Local Real Estate Advisors
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/Photo_3.jpeg"
                        alt="Photo-1"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-six */}
        <div className="help-section">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="padding-top-60">
                  <div className="margin-bottom-20">
                    <h2 className="heading-large">
                      Sell Real Estate the Right Way
                    </h2>
                  </div>
                  <div className="margin-bottom-20">
                    <p>
                      Doorsey.com is here to give real estate professionals
                      their first commerce platform. See for yourself what sets
                      Doorsey.com apart and book a no-obligation demo today.
                    </p>
                  </div>
                  <ButtonComponent
                    onClick={() =>
                      window.open(
                        "https://app.auction.io/login?plan=1&theme=99"
                      )
                    }
                    label="Register in Doorsey"
                    className="hs-button primary large"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ourBrandsWrapper">
          <h3
            style={{
              fontFamily: "Playball",
              fontSize: "34px",
              padding: "0 8px 0 0",
            }}
          >
            Our Brands
          </h3>
          <div className="brandsImgCnt">
            <a
              href="https://www.auctionsoftware.com/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/auction_logo_name.svg"
                alt="AuctionPay.com Logo"
                width="200"
                height="40"
              />
            </a>
            <a href="https://auction.io" target="_blank" rel="noreferrer">
              <img
                src="/images/auction_io_name.svg"
                alt="AuctionPay.com Logo"
                width="200"
                height="40"
              />
            </a>

            <a
              href="https://paymentgateway.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/payment_gateway_name.svg"
                alt="AuctionPay.com Logo"
                width="200"
                height="40"
              />
            </a>
            <a
              href="https://simpleauctionsite.com"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src="/images/simple_auction_name.svg"
                alt="AuctionPay.com Logo"
                width="200"
                height="40"
              />
            </a>
            <a href="https://doorsey.com" target="_blank" rel="noreferrer">
              <img
                src="/images/doorsey_name.svg"
                alt="AuctionPay.com Logo"
                width="100"
                height="40"
              />
            </a>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
