import React from "react";
import ButtonComponent from "../../Component/Button";
import "./Features.css";

const Features = () => {
  return (
    <>
      <div className="main-wrapper">
        {/* Section-one */}
        <div className="feature-hero-section">
          <div className="side-padding">
            <div className="vertical-spacing home-hero-spacing">
              <div className="container-new">
                <div className="features-hero-wrapper">
                  <div className="margin-bottom-24">
                    <h1 className="heading-extralarge">
                      Your Listings Powered by Doorsey.com
                    </h1>
                  </div>
                  <div className="margin-bottom-40">
                    <div className="cta-sub-text">
                      For the first time ever, agents have a reason to send
                      buyers to their own website
                      <br />
                      to do what they can’t do anywhere else—buy online,
                      anytime, from anywhere
                    </div>
                  </div>
                  <ButtonComponent
                    onClick={() =>
                      window.open(
                        "https://app.auction.io/login?plan=1&theme=99"
                      )
                    }
                    label="Sign me up!"
                    className="hs-button primary"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-two */}
        <div className="features-points-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="features-grid">
                  <div className="features-flex">
                    <div className="features-icon-wrap">
                      <img
                        src="images/icon1.svg"
                        alt="Icon"
                        className="image-100"
                      ></img>
                    </div>
                    <div className="features-text-wrap">
                      <div className="margin-bottom-8">
                        <h3 className="features-title drk">
                          Modern Online Experience
                        </h3>
                      </div>
                      <p className="paragraph-large">
                        Your clients will have a delightful frictionless online
                        experience with access to exclusive listing information
                        and robust buying tools directly from your website.
                      </p>
                    </div>
                  </div>

                  <div className="features-flex">
                    <div className="features-icon-wrap">
                      <img
                        src="images/icon1.svg"
                        alt="Icon"
                        className="image-100"
                      ></img>
                    </div>
                    <div className="features-text-wrap">
                      <div className="margin-bottom-8">
                        <h3 className="features-title drk">
                          Efficient Online Offers
                        </h3>
                      </div>
                      <p className="paragraph-large">
                        Multiple buying options and online offers makes the
                        process easier for everyone involved. Homes sell at true
                        market price and your clients are no longer hindered by
                        rounds of negotiation.
                      </p>
                    </div>
                  </div>

                  <div className="features-flex">
                    <div className="features-icon-wrap">
                      <img
                        src="images/icon1.svg"
                        alt="Icon"
                        className="image-100"
                      ></img>
                    </div>
                    <div className="features-text-wrap">
                      <div className="margin-bottom-8">
                        <h3 className="features-title drk">Build Your Brand</h3>
                      </div>
                      <p className="paragraph-large">
                        When buyers have a reason to visit your website, view
                        exclusive content and due diligence, they’ll keep coming
                        back even after the showing.
                      </p>
                    </div>
                  </div>

                  <div className="features-flex">
                    <div className="features-icon-wrap">
                      <img
                        src="images/icon1.svg"
                        alt="Icon"
                        className="image-100"
                      ></img>
                    </div>
                    <div className="features-text-wrap">
                      <div className="margin-bottom-8">
                        <h3 className="features-title drk">
                          Maximize Your Leads
                        </h3>
                      </div>
                      <p className="paragraph-large">
                        Adding Doorsey.com’s features and services will maximize
                        your leads by keep buyers on your website, instead of
                        allowing them to become a lead from a third-party
                        platform.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-three */}
        <div className="benefits-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Elevated Listings</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Our listing template allows you to add unique and
                        original content that can not be seen on any other site.
                        Buyers and sellers alike will be drawn to your appealing
                        listings, leading to more business.
                      </p>
                    </div>
                    <div className="flexed-points-wrapper">
                      <ul className="features-points-list">
                        <li>
                          <div>Robust photo gallery and Matterport</div>
                        </li>
                        <li>
                          <div>Home pre-inspection</div>
                        </li>
                        <li>
                          <div>Due diligence documents</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/image-2.png"
                        alt="image"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-four */}
        <div className="benefits-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex reverse">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Online Offers</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Buyers can make offers right on your website for all to
                        see. They will have a choice of how and when they buy.
                        Buyers compete on price alone and know exactly where
                        their offer stands, taking out the guesswork and the
                        need for negotiation.
                      </p>
                    </div>
                    <div className="flexed-points-wrapper">
                      <ul className="features-points-list">
                        <li>
                          <div>Multiple buying options available</div>
                        </li>
                        <li>
                          <div>Efficient paperless offers</div>
                        </li>
                        <li>
                          <div>Transparent offer platform</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/image-3.png"
                        alt="image"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-four */}
        <div className="benefits-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Enhanced Communication</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Engage buyers and sellers with our comprehensive
                        communication features. We offer real time listing
                        notifications direct to the buyer and seller, and a
                        forum for buyer questions on each listing.
                      </p>
                    </div>
                    <div className="flexed-points-wrapper">
                      <ul className="features-points-list">
                        <li>
                          <div>
                            Full suite of offer and listing notifications
                          </div>
                        </li>
                        <li>
                          <div>Visible listing Q&A</div>
                        </li>
                        <li>
                          <div>Client email and text</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/image-4.png"
                        alt="image"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-five */}
        <div className="feature-testimonial-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="features-testimonial-wrapper">
                  <div className="features-testimonial-text-wrap">
                    <div className="quote-icon">
                      <img
                        src="images/coma.svg"
                        alt="coma"
                        className="image-100"
                      />
                    </div>
                    <div className="margin-bottom-20">
                      <p className="feature-testimonial-para">
                        Doorsey.com’s platform is the only one of its kind
                        focusing on what is best for buyers and sellers by
                        bringing transparency and simplicity to the transaction.
                      </p>
                    </div>
                    <div className="feature-testimone-txt">
                      <strong>Mathew Speer</strong> - Broker | Local Real Estate
                      Advisors
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-six */}
        <div className="benefits-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex reverse">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Better Analytics</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Gain access to data that will help to predict client
                        behavior patterns, identify listing trends, and improve
                        the quality of your services. Doorsey.com users have the
                        ability to see key indicators that put your business on
                        the road to success.
                      </p>
                    </div>
                    <div className="flexed-points-wrapper">
                      <ul className="features-points-list">
                        <li>
                          <div>Lead data</div>
                        </li>
                        <li>
                          <div>Listing pipeline</div>
                        </li>
                        <li>
                          <div>Buyer preferences</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/image-5.png"
                        alt="image"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-seven */}
        <div className="benefits-section wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="benefit-flex">
                  <div className="benefir-left">
                    <div className="margin-bottom-16">
                      <h2 className="heading-large">Capture Leads</h2>
                    </div>
                    <div className="margin-bottom-24">
                      <p className="benefits-para">
                        Doorsey.com listings are uniquely designed to collect
                        buyer leads. Not only will you be able to keep your
                        leads, but you’ll also have access to additional
                        messaging and lead tracking tools.
                      </p>
                    </div>
                    <div className="flexed-points-wrapper">
                      <ul className="features-points-list">
                        <li>
                          <div>Message a lead directly from your website</div>
                        </li>
                        <li>
                          <div>Easy to use lead tracking</div>
                        </li>
                        <li>
                          <div>Stop paying for 3rd party leads</div>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="benefir-right">
                    <div className="benefit-image-wrap">
                      <img
                        src="images/image-6.png"
                        alt="image"
                        className="image-100"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-eight */}
        <div className="feature-testimonial-section t2 wf-section mt-5">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="features-testimonial-wrapper">
                  <div className="features-testimonial-text-wrap">
                    <div className="quote-icon">
                      <img
                        src="images/coma.svg"
                        alt="coma"
                        className="image-100"
                      />
                    </div>
                    <div className="margin-bottom-20">
                      <p className="feature-testimonial-para">
                        This is what the industry needs. Doorsey.com is designed
                        to get the seller the best price and the buyer knows
                        exactly what they’re getting. Everyone has the
                        information they need to do their due diligence, it’s a
                        level playing field.
                      </p>
                    </div>
                    <div className="feature-testimone-txt">
                      <strong>Denny Faircloth</strong> - Realtor, eXp Realty
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* Section-nine */}
        <div className="help-section">
          <div className="side-padding">
            <div className="vertical-spacing">
              <div className="container-new">
                <div className="padding-top-60">
                  <div className="margin-bottom-20">
                    <h2 className="heading-large">
                      Are You Ready to Discover the Doorsey.com Difference?
                    </h2>
                  </div>
                  <div className="margin-bottom-20">
                    <p>
                      Doorsey.com is here to give real estate professionals
                      their first commerce platform. See for yourself what sets
                      Doorsey.com apart and book a no-obligation demo today.
                    </p>
                  </div>
                  <ButtonComponent
                    onClick={() =>
                      window.open(
                        "https://app.auction.io/login?plan=1&theme=99"
                      )
                    }
                    label="Register in Doorsey"
                    className="hs-button primary large"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Features;
