import React, { Fragment } from "react";
import ButtonComponent from "../Button";
import InputField from "../InputField/InputField";
import "./Footer.css";

const Footer = () => {
  return (
    <Fragment>
      <footer className="section__footer">
        <div className="page-padding">
          <div className="container__large">
            <div className="w-layout-grid footer__grid">
              <div className="footer__grid-wrapper">
                <h4 className="footer__heading">Product</h4>
                <a
                  href="https://app.doorsey.com/"
                  target="_blank"
                  className="footer-link"
                >
                  Buy Now
                </a>
                <a href="/features" className="footer-link">
                  Features
                </a>
                <a href="/faqs" className="footer-link">
                  FAQs
                </a>
                <a
                  target="_blank"
                  href="https://app.auction.io/login?plan=1&theme=99"
                  className="footer-link"
                >
                  Register in Doorsey
                </a>
              </div>
              <div className="footer__grid-wrapper">
                <h4 className="footer__heading">Company</h4>
                <a href="/about" className="footer-link">
                  About
                </a>
                <a href="/terms_and_conditions" className="footer-link">
                  Terms and conditions
                </a>
              </div>
              {/* <div className="footer__grid-wrapper">
                <h4 className="footer__heading">Connect</h4>
                <a href="/facebook" className="footer-link">
                  Facebook
                </a>
                <a href="/twitter" className="footer-link">
                  Twitter
                </a>
                <a href="/linkedin" className="footer-link">
                  Linkedin
                </a>
                <a href="/instagram" className="footer-link">
                  Instagram
                </a>
              </div> */}
              <div className="footer__grid-wrapper">
                <h4 className="footer__heading">Contact</h4>
                {/* <a href="/email" className="footer-link">
                  support@auction.io
                </a> */}
                <a href="tel:+19722005516" className="footer-link">
                  Call or text <br />
                  +1-972-200-5516
                </a>
              </div>
              {/* <div className="footer__grid-wrapper">
                <h4 className="footer__heading">Newsletter</h4>
                <div className="footer__text">Join our mailing list</div>
                <div className="w-embed w-script">
                  <div className="footer-field">
                    <InputField
                      labelCls="email"
                      labelName="Email"
                      className="textings"
                      placeholder="email@example.com"
                    />
                  </div>
                </div>
                <ButtonComponent label="Submit" className="hs-button primary" />
              </div> */}
            </div>
          </div>
        </div>
        <div className="footer-bottom-bar">
          <div className="side-padding">
            <div className="container-new">
              <div className="footer-strip-flex">
                <div className="footer-stripe-text">
                  © 2023 - Doorsey.com, An auction.io company | All rights
                  reserved.
                  <br />
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </Fragment>
  );
};

export default Footer;
