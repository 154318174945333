import React from "react";
import ArrowForwardIosIcon from "@material-ui/icons/ArrowForwardIos";
import Accordion from "@material-ui/core/Accordion";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import PropTypes from "prop-types";
import "./Faqs.css";
import ButtonComponent from "../../Component/Button";
import { Box, Tab, Tabs, Typography } from "@material-ui/core";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div>{children}</div>}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function Faqs() {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className="main-wrapper">
      {/* Section-one */}
      <div className="faq-hero-section">
        <div className="side-padding">
          <div className="vertical-spacing home-hero-spacing">
            <div className="container-new">
              <div className="faq-hero-wrap">
                <div>
                  <h1 className="heading-extralarge faq-heading">
                    You’ve got questions, we have answers
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section-two */}
      <div className="faq-section wf-section mb-5">
        <div className="side-padding">
          <div className="vertical-spacing">
            <div className="container-new">
              <div className="faq-parent-wrap">
                <div className="faq-link-menu">
                  <Tabs
                    value={value}
                    onChange={handleChange}
                    textColor="primary"
                    indicatorColor="primary"
                    aria-label="basic tabs example"
                    className="faq-link-grid"
                  >
                    <Tab
                      className="faq-anchor-link"
                      label="General"
                      {...a11yProps(0)}
                    />
                    <Tab
                      className="faq-anchor-link"
                      label="Process"
                      {...a11yProps(1)}
                    />
                    <Tab
                      className="faq-anchor-link"
                      label="Agents"
                      {...a11yProps(2)}
                    />
                  </Tabs>
                </div>
                <TabPanel className="faq-parent" value={value} index={0}>
                  <div className=" accTitle">
                    <h2 className="heading-large">General</h2>
                  </div>
                  {/* Part-1,Question-1 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> What is Doorsey.com?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Doorsey.com is a modern commerce and management
                          solution for real estate professionals.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-1,Question-2 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {" "}
                          What problems does Doorsey.com solve?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Today’s current process of buying and selling is not
                          keeping pace with your clients' demands. Doorsey.com
                          offers leading-edge technology to help brokerages sell
                          homes online, offline, or in person. Our platform
                          gives brokerages all the benefits of commerce
                          including upselling, analytics, and brand building.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-1,Question-3 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {" "}
                          Is Doorsey.com a lead generation company?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Doorsey.com does not generate leads per se, but we do
                          specialize in capturing contact information from
                          buyers who view exclusive listing content. You'll be
                          able to view and work your buyer leads through the
                          store administration panel.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-1,Question-4 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          {" "}
                          What are the different offer formats?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Buying on a Doorsey.com store is flexible and easy. We
                          offer 4 different options to meet the needs of buyers
                          and sellers.
                          <br /> - Buy Now: This is a fixed price with pre-set
                          terms to allow ultimate speed and confidence. ‍<br />{" "}
                          - Make an Offer: Allows buyers to submit a price-only
                          at pre-set terms for ultimate flexibility. ‍<br /> -
                          Auction: Rules and timelines are set up front. All
                          buyers compete in a public setting. ‍<br /> -
                          Traditional: For sellers who prefer more discretion,
                          we allow offers to be sent off platform, but you still
                          get all the benefits of the workflows, communication,
                          and automation the platform provides.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-1,Question-5 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> Is there training and support?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The Doorsey.com team is here to help you with anything
                          you need. Whether it's posting a helpful article or
                          1:1 coaching, our team is here for you.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-1,Question-6 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography> What is the monthly cost?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          We have a range of pricing options for different-sized
                          brokerages and their specific needs; we'd be delighted
                          to meet with you to discuss the best fit for you and
                          your teams.
                          {/* Click{" "}
                          <a
                            href="https://app.auction.io/login?plan=1&theme=99"
                            target="_blank"
                          >
                            here
                          </a>{" "}
                          to book a demo. */}
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={1}>
                  <div className=" accTitle">
                    <h2 className="heading-large">Process</h2>
                  </div>
                  {/* Part-2,Question-1 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          Do I need to hire a developer to integrate Doorsey.com
                          into my website?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          No, Doorsey.com will handle the integration of your
                          online store once you've completed your store set up.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-2,Question-2 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          Do you replace my whole website?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The Doorsey.com solution will not replace your
                          existing website. Once your store is set up and
                          integrated, buyers will seamlessly navigate from your
                          home page to your online store.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-2,Question-3 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          How long does it take to set up my store?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          After you complete the Online Store Configuration from
                          your administration panel, your store will be fully
                          functional. The Doorsey team handles the integration
                          of your store to your existing website within 24 hours
                          of your store's set up.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-2,Question-4 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Is there training and support?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          The Doorsey.com team is here to help you with anything
                          you need. Whether it's posting a helpful article or
                          1:1 coaching, our team is here for you.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </TabPanel>
                <TabPanel value={value} index={2}>
                  <div className=" accTitle">
                    <h2 className="heading-large">Agents</h2>
                  </div>
                  {/* Part-3,Question-1 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>Do you help with financing?</Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          No, Doorsey.com is neither a mortgage broker nor a
                          lender. If you would like assistance finding a lender,
                          please contact us, and we'll be happy to provide you a
                          list of local providers.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                  {/* Part-3,Question-2 */}
                  <div className="faq">
                    <Accordion>
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography>
                          Can an agent register as a bidder and bid on behalf of
                          the buyer?
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <Typography>
                          Yes. It is very common for agents to register and bid
                          on behalf of their client.
                        </Typography>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                </TabPanel>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Section-three */}
      <div className="section__questions background-black">
        <div className="page-padding">
          <div className="container__large">
            <div className="faqs-text-wrap">
              <h2 className="heading-lar mt-5 mb-5">Still have questions?</h2>
              <p className="faqs-para mb-5">
                Click below to book a demo with our team.
                <br />
                We’ll walk you through the process and answer any remaining
                questions.
              </p>
              <ButtonComponent
                onClick={() =>
                  window.open("https://app.auction.io/login?plan=1&theme=99")
                }
                label="Register in Doorsey"
                className="hs-button primary large"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
