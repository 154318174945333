import { TextField } from "@material-ui/core";
import React, { Fragment } from "react";
import "./InputField.css";
const InputField = (props) => {
  return (
    <>
      <label className={props.labelCls}>
        <span>{props.labelName}</span>
        <span className="hs-form-required">*</span>
      </label>
      <TextField
        id={props.id}
        label={props.label}
        className={props.className}
        placeholder={props.placeholder}
      />
    </>
  );
};

export default InputField;
