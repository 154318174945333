import Button from "@material-ui/core/Button";
import React from "react";
import "./Button.css";
const ButtonComponent = (props) => {
  return (
    <>
      <Button
        variant="contained"
        onClick={props.onClick}
        id={props.id}
        className={props.className}
      >
        {props.label}
      </Button>
    </>
  );
};

export default ButtonComponent;
