import React, { useEffect, useContext } from "react";
import {
  Route,
  BrowserRouter as Router,
  withRouter,
  Switch,
} from "react-router-dom";
import Book_A_Demo from "../View/Book_A_Demo/Book_A_Demo";
import Faqs from "../View/Faqs/Faqs";
import HubspotContactUs from "../View/ContactUs";
import Features from "../View/Features/Features";
import Home from "../View/Home/Home";
import Footer from "../Component/Footer/Footer";
import Navbar from "../Component/Navbar/Navbar";

const Routes = () => {
  return (
    <>
      <div className="plain-body">
        <Navbar />
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/features" component={Features} />
          <Route exact path="/faqs" component={Faqs} />
          <Route exact path="/book-a-demo" component={HubspotContactUs} />
          <Route exact path="/demo" component={Book_A_Demo} />
        </Switch>
        <Footer />
      </div>
    </>
  );
};
export default withRouter(Routes);
