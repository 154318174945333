import React from "react";
import ButtonComponent from "../../Component/Button";
import InputField from "../../Component/InputField/InputField";
import "./Book_A_Demo.css";

const Book_A_Demo = () => {
  return (
    <>
      <div className="main-wrapper">
        {/* Secion-one */}
        <div className="new-home-hero book-demo">
          <div className="side-padding">
            <div className="vertical-spacing home-hero-spacing">
              <div className="container-new">
                <div className="hero-flex">
                  <div className="hero-content-left">
                    <div className="margin-bottom-200">
                      <h4>Modern Commerce for Real Estate</h4>
                    </div>
                    <div className="margin-bottom-200">
                      <h1 className="heading-extra">
                        Book
                        <br />a demo
                      </h1>
                    </div>
                    <p>
                      Want to see the future? See how Doorsey.com’s
                      modern commerce
                      <br />
                      platform and elevated buying experience are
                      revolutionizing the real estate
                      <br />
                      industry.To learn more, submit your information and book a
                      meeting with our team.
                    </p>
                  </div>
                  <div className="hero-content-right">
                    <div className="hero-form-wrapper px-4 py-4">
                      <InputField
                        labelCls="first_name form-label"
                        labelName="First name"
                        className="texting text"
                        placeholder="John"
                      />
                      <InputField
                        labelCls="first_name form-label"
                        labelName="Last name"
                        className="texting text"
                        placeholder="Smith"
                      />
                      <InputField
                        labelCls="first_name form-label"
                        labelName="Email"
                        className="texting text"
                      />
                      <InputField
                        labelCls="first_name form-label"
                        labelName="Phone number"
                        className="texting text"
                        placeholder="1 (123) 456-7890"
                      />
                      <InputField
                        labelCls="first_name form-label"
                        labelName="Your role or job title"
                        className="texting text"
                        placeholder="Please Select"
                      />
                      <InputField
                        labelCls="first_name form-label"
                        labelName="How did you hear about Doorsey.com?"
                        className="texting text"
                      />
                      <ButtonComponent
                        label="Schedule a meeting"
                        className="hs-button primary large mt-4 mb-4"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Book_A_Demo;
